import React from 'react'
import 'typeface-libre-baskerville'
import { UserProvider } from './src/components/UserContext'
import "./src/styles/global.scss"

export const wrapRootElement = ({ element }) => (
  <UserProvider>
    {element}
  </UserProvider>
)

// goat-counter config
window.goatcounter = {
  // i.e. ignore location.hash
  path: window.location.pathname
    ? window.location.pathname + window.location.search
    : `/`,
}

// remove loading page
if(typeof window !== undefined){
  const loadingCheck = window.setInterval(() => {
    const hasLoaded = document.querySelector(`#header`) !== null
    if(hasLoaded){
      const loadingContainer = document.querySelector(`#loading-page-container`)
      if(loadingContainer !== null){
        loadingContainer.remove()
      }
      window.clearInterval(loadingCheck)
    }
  }, 100)
}

import React, { useCallback, useEffect, useState } from 'react'
import Api from '../../lib/Api'
import Auth from '../../lib/Auth'
import Cookie from '../../lib/Cookie'
import type { User } from '../../types/unicornvalley'

interface State {
  user: User,
  updateMe: () => void,
  setMe: (u: User) => void
}

const defaultState:State = {
  setMe: () => {},
  updateMe: () => {},
  user: {} as User, 
}

const UserContext = React.createContext(defaultState)

export const UserProvider = ({
  children,
}) => {

  const [me, setMe] = useState({} as User)

  const fetchAndStoreMe = useCallback(async () => {
    const loggedIn = Auth.isLoggedIn()
    if(loggedIn) {
      const token = Cookie.getJWT()
      const user = await Api.getMe(token)
      setMe({ ...user } as User)
    } else {
      setMe(defaultState.user)
    }
  }, [setMe])

  useEffect(() => {
    (async () => {
      fetchAndStoreMe()
    })()
  }, [fetchAndStoreMe])

  return (
    <UserContext.Provider value={{
      setMe,
      updateMe: fetchAndStoreMe,
      user: me,
    }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
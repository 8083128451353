import UniversalCookies from 'universal-cookie'
import { Environment, EnvironmentVariables } from './Constants'

const C = new UniversalCookies()

class Cookie {
  private static JWT_COOKIE_NAME = `uv-auth`

  static setCookie = (key: string, value: String | Object, parameters = {}) => C.set(key, value, { ...parameters })
  static getCookie = (key: string, options = {}) => C.get(key, { ...options })
  static deleteCookie = (key: string, options = {}) => C.remove(key, { ...options })


  private static JWTOptions = {
    ...(
      // if domain is not set, subdomain cookies will not be shared with primary domain
      EnvironmentVariables.ENVIRONMENT === Environment.staging && {
        domain: EnvironmentVariables.BASE_DOMAIN,
      }
    ),
    path: `/`,
    ...(
      [
        Environment.staging,
        Environment.production,
      ].includes(EnvironmentVariables.ENVIRONMENT) && {
      sameSite: `Strict`,
      secure: true,
    }),
  }

  static setJWT = (token: string, expires: Date) => Cookie.setCookie(
    Cookie.JWT_COOKIE_NAME,
    token,
    { expires, ...Cookie.JWTOptions },
  )
  static getJWT = () => Cookie.getCookie(
    Cookie.JWT_COOKIE_NAME,
  )
  static removeJWT = () => Cookie.deleteCookie(
    Cookie.JWT_COOKIE_NAME,
    { ...Cookie.JWTOptions },
  )
}

export default Cookie
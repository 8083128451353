class Logger {
  // static logInfo = ({
  //   type = `default`,
  //   level = Severity.Info,
  //   category,
  //   data,
  //   message: string,
  // }: Breadcrumb) => {
  //   Sentry.addBreadcrumb({

  //   })
  // }
  static logEvent = (event) => {
    console.log(event)
  }
  static logError = (error) => {
    console.error(error)
  }
}

export default Logger
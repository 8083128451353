import axios from 'axios'
import type {
  Company, JWT,
  LoginResponse, LoginResult, RawCompany, RawUser, User,
} from '../types/unicornvalley'
import { EnvironmentVariables, StatusCodes } from './Constants'
import Logger from './Logger'

const oauthTypes = {
  Facebook: `FB`,
  Google: `GG`,
}

export type oauthType = typeof oauthTypes[keyof typeof oauthTypes]

class Api {

  private static baseUrl = EnvironmentVariables.API_BASE_URL
  private static req = axios.create({
    baseURL: Api.baseUrl,
  })
  static oauthTypes = oauthTypes

  private static generateAuthorizationHeader = (token: JWT) => ({
    Authorization: `Bearer ${token}`,
  })

  static parseUser = (user: RawUser, { showRemovedCompanies = false } = {}): User => {
    const {
      user_id: id,
      created_at: createdAt,
      updated_at: updatedAt,
      companies,
      ...otherAttributes
    } = user
    return {
      ...otherAttributes,
      companies: (companies && Array.isArray(companies))
        ? companies
          .map(coy => Api.parseCompany(coy))
          .filter(({ isRemoved }) => !isRemoved || showRemovedCompanies)
        : undefined,
      createdAt: new Date(createdAt),
      id,
      updatedAt: new Date(updatedAt),
    }
  }

  static getUser = async (
    // eslint-disable-next-line quotes
    userId: User["id"],
    {
      showRemovedCompanies = false,
    } = {},
  ): Promise<User> => {
    try {
      const { data } = await Api.req.get(`/users/${userId}`)
      const { user, companies } = data
      return Api.parseUser({
        ...user,
        companies,
      }, {
        showRemovedCompanies,
      })
    } catch (error) {
      Logger.logError(error)
      throw error
    }
  }

  static parseCompany = (coy: RawCompany): Company => {
    const {
      company_id: id,
      created_at: createdAt,
      updated_at: updatedAt,
      creator,
      is_removed: isRemoved,
      ...otherAttributes
    } = coy
    return {
      ...otherAttributes,
      createdAt: new Date(createdAt),
      creator: (creator)
        ? Api.parseUser(creator)
        : undefined,
      id,
      isRemoved,
      updatedAt: new Date(updatedAt),
    }
  }

  static unparseCompany = (coy: Company): RawCompany => {
    const {
      id,
      isRemoved,
      ...otherAttributes
    } = coy
    return {
      ...otherAttributes as unknown as RawCompany,
      is_removed: isRemoved,
    }
  }

  static getCompanies = async ({ showRemoved = false } = {}): Promise<Company[]> => {
    try {
      const { data }: { data: RawCompany[] } = await Api.req.get(`/company/`)
      if (data) {
        return data
          .map((coy) => Api.parseCompany(coy))
          .filter(({ isRemoved }) => !isRemoved || showRemoved)
      }
      return []
    } catch (error) {
      Logger.logError(error)
      throw error
    }
  }

  static getCompany = async (
    // eslint-disable-next-line quotes
    coyId: Company["id"],
  ): Promise<Company> => {
    try {
      const { data } = await Api.req.get(`/company/${coyId}`)
      return Api.parseCompany(data)
    } catch (error) {
      Logger.logError(error)
      throw error
    }
  }

  static postCompany = async (token: JWT, company: Company): Promise<Company> => {
    try {
      const { data } = await Api.req.post(`/company/`, { ...Api.unparseCompany(company) }, {
        headers: Api.generateAuthorizationHeader(token),
      })
      return Api.parseCompany(data)
    } catch (error) {
      Logger.logError(error)
      throw error
    }
  }

  static patchCompany = async (token: JWT, companyId, company: Company): Promise<Company> => {
    try {
      const { data } = await Api.req.patch(`/company/${companyId}`, { ...Api.unparseCompany(company) }, {
        headers: Api.generateAuthorizationHeader(token),
      })
      return Api.parseCompany(data)
    } catch (error) {
      Logger.logError(error)
      throw error
    }
  }

  static uploadCompanyPicture = async (
    token: JWT,
    // eslint-disable-next-line quotes
    companyId: Company["id"],
    picture: File): Promise<Company> => {
    try {
      const formData = new FormData()
      formData.append(`picture`, picture)
      const { data } = await Api.req.patch(`/company/${companyId}/picture`, formData, {
        headers: Api.generateAuthorizationHeader(token),
      })
      return Api.parseCompany(data)
    } catch (error) {
      Logger.logError(error)
      throw error
    }
  }

  // TODO: this should be uploadProfilePicture, because there's really no reason
  // you should be uploading anyone else's profile picture
  static uploadUserPicture = async (
    token: JWT,
    // eslint-disable-next-line quotes
    userId: User["id"],
    picture: File): Promise<User> => {
    try {
      const formData = new FormData()
      formData.append(`picture`, picture)
      const { data } = await Api.req.patch(`/users/${userId}/picture`, formData, {
        headers: Api.generateAuthorizationHeader(token),
      })
      return Api.parseUser(data)
    } catch (error) {
      Logger.logError(error)
      throw error
    }
  }

  static userLogin = async (
    // eslint-disable-next-line quotes
    username: User["username"],
    // eslint-disable-next-line quotes
    password: User["password"],
  ): Promise<LoginResult> => {
    try {
      const { data } = await Api.req.post(`/jwt/login`, { password, username })
      const { code, expire, token }: LoginResponse = data
      if (code === 200) {
        return { expiry: new Date(expire), token }
      }
      return { error: `Oops, an error occurred whilst logging in` }
    } catch (error) {
      if (error.response.status === StatusCodes.Unauthorized) {
        return { error: `Invalid credentials` }
      }
      Logger.logError(error)
      throw error
    }
  }

  static oauthLogin = async (authType: oauthType, oauthResponse): Promise<LoginResult> => {
    try {
      const { data } = await Api.req.post(`/login/oauth`, {
        authtype: authType,
        data: oauthResponse,
      })
      const { code, expire, token }: LoginResponse = data
      if (code === 200) {
        return { expiry: new Date(expire), token }
      }
      Logger.logError(new Error(`OAuth Error: ${code}; ${JSON.stringify(data)}`))
      return { error: `Oops, an error occurred during the oauth login` }
    } catch (error) {
      Logger.logError(error)
      throw error
    }
  }

  static userRegister = async (
    // eslint-disable-next-line quotes
    name: User["name"],
    // eslint-disable-next-line quotes
    username: User["username"],
    // eslint-disable-next-line quotes
    password: User["password"],
  ): Promise<User> => {
    try {
      const { data }: { data: RawUser } = await Api.req.post(`/users/`, { name, password, username })
      return Api.parseUser(data)
    } catch (error) {
      Logger.logError(error)
      throw error
    }
  }

  static getMe = async (token: JWT): Promise<User> => {
    try {
      const { data } = await Api.req.get(`/jwt/hello`, {
        headers: Api.generateAuthorizationHeader(token),
      })
      return Api.parseUser(data)
    } catch (error) {
      Logger.logError(error)
      throw error
    }
  }

  // TODO: this should be patchMe, because there's really no reason
  // you should be patching anyone else
  static patchUser = async (
    token: JWT,
    // eslint-disable-next-line quotes
    userId: User["id"],
    user: User,
  ): Promise<User> => {
    try {
      const { data } = await Api.req.patch(`/users/${userId}`, { ...user }, {
        headers: Api.generateAuthorizationHeader(token),
      })
      return Api.parseUser(data)
    } catch (error) {
      Logger.logError(error)
      throw error
    }
  }
}

export default Api